import React, { useState } from "react";
import { IoMdWarning } from "@react-icons/all-files/io/IoMdWarning";
import { MdDone } from "@react-icons/all-files/md/MdDone";

import { IModalContext, useLanguage } from "Providers";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { Box, Button, Flex, Header, Stack, Text } from "Atoms";
import { BOCard, BOCardBody } from "../../shared";
import { modalNames } from "Constants";
import { AccountAuthenticationSettings } from "Types";

type Props = {
    fortnoxAuthentications: AccountAuthenticationSettings[];
    companyShops: { id: string; name: string }[];
    shopIdsToConnect: string[];
    connectShopsToFortnox: (shopIds: string[], authenticationId?: string) => void;
};

export type FortnoxModalProps = Props;

export const FortnoxConnectionModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const [selectedConnection, setSelectedConnection] = useState<{ id?: string; isNew: boolean }>({
        id: modalContent.fortnoxAuthentications[0].id,
        isNew: false
    });
    const { translate } = useLanguage();
    const { fortnoxAuthentications, shopIdsToConnect, companyShops, connectShopsToFortnox } = modalContent;

    const onSetSelectedConnection = (authenticationId?: string) => {
        setSelectedConnection({ id: authenticationId, isNew: !authenticationId });
    };

    const onConnectShopsToConnection = () => {
        connectShopsToFortnox(shopIdsToConnect, selectedConnection?.id);
        closeModal(modalNames.FORTNOX_CONNECTION_MODAL);
    };

    const shopsToConnect = shopIdsToConnect.map(id => companyShops?.find(shop => shop.id === id)?.name);
    const newConnectionSelected = selectedConnection?.isNew;

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.FORTNOX_CONNECTION_MODAL)} size="xl" isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.FORTNOX_CONNECTION_MODAL)} />
            <ModalHeader>
                <Box ml={4}>{translate("chooseConnection")}</Box>
            </ModalHeader>
            <ModalBody overflow="auto">
                {fortnoxAuthentications.map((auth: AccountAuthenticationSettings, index: number) => {
                    const shops = companyShops
                        .filter(value => auth.shopIds?.includes(value.id))
                        .map(value => value.name);
                    const isSelected = selectedConnection?.id === auth.id;

                    return (
                        <BOCard
                            width="100%"
                            mb={5}
                            bg={isSelected ? "blue.100" : "white"}
                            cursor="pointer"
                            key={auth.id}
                            onClick={() => onSetSelectedConnection(auth.id)}
                        >
                            <BOCardBody>
                                <Flex alignItems="center" justifyContent="space-between">
                                    <Stack width="100%">
                                        <Header size="md">{`${translate("connection")} ${index + 1}`}</Header>
                                        <Text mb={2} whiteSpace="pre-line" width="90%">
                                            <Text as="b">{translate("restaurants")}: </Text> {shops.join(", ")}
                                        </Text>
                                        {isSelected && (
                                            <Text mb={2} whiteSpace="pre-line" width="100%">
                                                <Text as="b">{`${translate("connect")}`}:</Text>{" "}
                                                {shopsToConnect.join(", ")}
                                            </Text>
                                        )}
                                    </Stack>
                                    {isSelected && <Box as={MdDone} fontSize="2rem" color="blue.500" />}
                                </Flex>
                            </BOCardBody>
                        </BOCard>
                    );
                })}

                <BOCard
                    width="100%"
                    mb={5}
                    cursor="pointer"
                    bg={newConnectionSelected ? "blue.100" : "white"}
                    onClick={() => onSetSelectedConnection()}
                >
                    <BOCardBody>
                        <Flex alignItems="center" justifyContent="space-between">
                            <Stack width="100%">
                                <Header size="md">{`${translate("new")} ${translate("connection")}`}</Header>
                                <Flex alignItems="center">
                                    <Box as={IoMdWarning} size="2rem" color="orange.500" />
                                    <Text>{translate("warningNewConnection")}</Text>
                                </Flex>
                                {newConnectionSelected && (
                                    <Text mb={2} whiteSpace="pre-line" width="90%">
                                        <Text as="b">{`${translate("connect")}`}:</Text> {shopsToConnect.join(", ")}
                                    </Text>
                                )}
                            </Stack>
                            {newConnectionSelected && <Box as={MdDone} fontSize="2rem" color="blue.500" />}
                        </Flex>
                    </BOCardBody>
                </BOCard>
            </ModalBody>
            <ModalActions>
                <Flex justifyContent="flex-end">
                    <Button themeColor="green" onClick={onConnectShopsToConnection}>
                        {translate("connect")}
                    </Button>
                </Flex>
            </ModalActions>
        </Modal>
    );
};
