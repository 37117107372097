import { normaliseAndReplaceSpecialCharacters } from "Utils/imageUtils";

export type ImageDimension = {
    fileName: string;
    width: number;
    height: number;
    imageRatio: number;
};

const fileNamePattern = /^[a-zA-Z0-9_.-]+$/;
export const isImageTitleValid = (imageTitle: string) => fileNamePattern.test(imageTitle);

/**
 * [FUNCTION] get image ratio used to resize by width
 * @param width - width of original image
 * @param height - height of original image
 * @returns {number} ratio
 * @example getImageRatio(1280, 640) => 0.5
 */
export const getImageRatio = (width: number, height: number) => {
    const imageRatio = 1.0 * (height / width);
    return Math.round((imageRatio + Number.EPSILON) * 100) / 100;
};

/**
 * [Function] async function to read the dimensions of loaded image
 * @param file image
 * @returns ImageDimension type
 * @example await getImageDimensions(file) => {fileName: new.jpg, width: 1280, height: 640, imageRatio: 0.5}
 */
export const getImageDimensions = async (file: File): Promise<ImageDimension> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e: ProgressEvent<EventTarget>) => {
            const image = new Image();
            //@ts-ignore
            image.src = e.target.result;
            image.onload = () =>
                resolve({
                    fileName: normaliseAndReplaceSpecialCharacters(file.name),
                    width: image.width,
                    height: image.height,
                    imageRatio: getImageRatio(image.width, image.height)
                });
            return;
        };
        reader.onerror = reject;
    });

/**
 * [FUNCTION] - remove the query from the url - if present
 * @param imageUrl
 * @returns
 */
const removeQueryFromUrl = (imageUrl: string) => {
    const removeQueryFromUrl = imageUrl.split("?");
    return !!removeQueryFromUrl.length ? removeQueryFromUrl[0] : imageUrl;
};

/**
 * [FUNCTION] - Promise to check that the image url is valid
 * @param imageUrl 
 * @returns 
 */
export const isImageUrlValid = (imageUrl: string) =>
    new Promise<boolean>((resolve, reject) => {
        const img = new Image();
        const url = removeQueryFromUrl(imageUrl);
        img.src = url;
        img.onload = () => {
            resolve(true);
        };
        img.onerror = () => {
            reject(false);
        };
    });
