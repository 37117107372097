import React from "react";
import { Field, getIn } from "formik";
import { Input, Form, TextArea, Checkbox } from "semantic-ui-react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { FieldArray } from "formik-next";
import "react-datepicker/dist/react-datepicker.css";

import { Box, Checkbox as ChkBox, Flex, FormLabel, Text } from "Atoms";

export const DatePickerInput = props => (
    <div style={{ verticalAlign: "text-top", ...props.styles }}>
        {props.placeholderText && <label className={"renderInputLabel"}>{props.placeholderText}:</label>}
        <DatePicker {...props} />
        {/*<ErrorMessage name={props.name} />*/}
    </div>
);

export const CustomerDatePickerInput = ({ label = "", mandatory = false, ...props }) => (
    <Flex mb="4" direction="column" verticalAlign="text-top">
        {label && (
            <FormLabel>
                {mandatory ? <span style={{ color: "red", fontWeight: "900" }}>* </span> : ""}
                {label}{" "}
            </FormLabel>
        )}
        <DatePicker {...props} />
    </Flex>
);

export const SelectInput = ({ field, isMandatory, label, form: { touched, errors }, ...props }) => {
    const { isMulti, options, setFieldValue } = props;
    const getValue = () => {
        if (options) {
            return isMulti
                ? _.intersectionBy(options, field.value, "value")
                : options.find(option => option.value === field.value);
        } else {
            return isMulti ? [] : "";
        }
    };

    const onChange = value => {
        let optionValue;
        if (isMulti) {
            optionValue = value ? value : [];
        } else {
            optionValue = value ? value.value : "";
        }
        setFieldValue(field.name, optionValue);
    };

    return (
        <div style={{ verticalAlign: "text-top", marginBottom: "1rem" }}>
            {label && (
                <label className={"renderInputLabel"}>
                    {label}
                    {isMandatory ? (
                        <Text as="span" color="red.500" ml={2} fontWeight="900">
                            *
                        </Text>
                    ) : (
                        ""
                    )}
                </label>
            )}
            <Select
                {...field}
                {...props}
                value={getValue()}
                onChange={onChange}
                onBlur={() => props.onBlur(field.name, true, true)}
            />
            <ErrorMessage name={field.name} />
        </div>
    );
};

export const FormInput = ({ field, label, form: { touched, errors }, ...props }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {label && <label className={"renderInputLabel"}>{label}</label>}
            <Form.Input type="text" {...field} {...props} placeholder={label} />
            <ErrorMessage name={field.name} />
        </div>
    );
};

export const TextInput = ({ field, label, isMandatory, form: { touched, errors }, ...props }) => {
    return (
        <div style={{ verticalAlign: "text-top", marginBottom: "1rem" }}>
            {label && (
                <label className={"renderInputLabel"}>
                    {label}
                    {isMandatory ? <span style={{ color: "red", fontWeight: "900" }}>* </span> : ""}
                </label>
            )}
            <Input type="text" {...field} {...props} />
            <ErrorMessage name={field.name} />
        </div>
    );
};

export const InputDatalist = ({
    field,
    setFieldValue,
    productNames,
    fieldLabel,
    isMandatory = false,
    form: { touched, errors },
    ...props
}) => (
    <div>
        <Box>
            {fieldLabel}
            {isMandatory && (
                <Text as="span" color="red.500" ml={2}>
                    *
                </Text>
            )}
        </Box>
        <Input
            list="productNames"
            placeholder="Välj produktnamn"
            style={{ margin: "0 0 10px 0" }}
            onChange={(_, res) => {
                setFieldValue(field.name, res.value);
            }}
            {...field}
            {...props}
        />
        {field.value && (
            <datalist id="productNames">
                {productNames.getDistinctProductNames
                    .filter(prod => prod.toLowerCase().includes(field.value.toLowerCase()))
                    .slice(0, 10)
                    .map(prod => (
                        <option value={prod} key={prod} />
                    ))}
            </datalist>
        )}
        <ErrorMessage name={field.name} />
    </div>
);

export const TextAreaInput = ({ field, label, form: { touched, errors }, ...props }) => {
    return (
        <div style={{ marginBottom: "1rem" }}>
            {label && <label className={"renderInputLabel"}>{label}</label>}
            <TextArea rows="16" style={{ minHeight: 100 }} {...field} {...props} />
            <ErrorMessage name={field.name} />
        </div>
    );
};

export const ErrorMessage = ({ name, backendError }) => (
    <Field
        name={name}
        render={({ form }) => {
            if (backendError) {
                return <div className="error">{backendError}</div>;
            }
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? <div className="error">{error}</div> : null;
        }}
    />
);

const InputFeedback = ({ error }) => {
    return error ? <div className={"error"}>{error}</div> : null;
};

// used for single purposes
export const CheckBoxSingleInput = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    disabled,
    id,
    toggle,
    label
}) => {
    return (
        <div style={{ verticalAlign: "text-top", paddingTop: "10px", paddingBottom: "10px" }}>
            {label && <label className={"renderInputLabel"}>{label}</label>}
            <Checkbox
                disabled={!!disabled}
                style={{ paddingTop: "3px", paddingLeft: "5px" }}
                id={id}
                name={name}
                toggle={toggle}
                checked={value}
                onChange={onChange}
            />
            <ErrorMessage name={name} />
        </div>
    );
};

// used together with checkbox group
export const CheckboxItem = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    ...props
}) => {
    return (
        <div style={{ marginBottom: "1rem" }}>
            <Checkbox
                name={name}
                id={id}
                label={label}
                type="checkbox"
                // value={value}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                className={"checkbox"}
            />
            {touched[name] && <InputFeedback error={errors[name]} />}
        </div>
    );
};

/** @deprecated move to RHCheckBoxGroupInput when using RHF */
export const CheckBoxGroupNext = ({ name, checkboxOptions, selectedValues }) => {
    if (!checkboxOptions) return null;

    return (
        <Flex direction="column">
            <FieldArray
                name={name}
                render={arrayHelpers =>
                    checkboxOptions.map(option => {
                        const checked = selectedValues.includes(option.id);
                        return (
                            <ChkBox
                                key={option.id}
                                label={option.name}
                                checked={checked}
                                value={option.id}
                                onChange={() => {
                                    if (!checked) {
                                        arrayHelpers.push(option.id);
                                    } else {
                                        const idx = selectedValues.indexOf(option.id);
                                        arrayHelpers.remove(idx);
                                    }
                                }}
                            >
                                <Box as="span" marginLeft={1}>
                                    {option.name}
                                </Box>
                            </ChkBox>
                        );
                    })
                }
            />
        </Flex>
    );
};

export const CheckboxGroup = props => {
    const handleChange = ({ target }) => {
        let valueArray = [...props.value] || [];
        if (target.checked) {
            valueArray = [...valueArray, target.id];
        } else {
            valueArray = valueArray.filter(v => v !== target.id);
        }

        props.onChange(props.id, valueArray);
    };

    const handleBlur = () => {
        // take care of touched
        props.onBlur(props.id, true);
    };

    const { value, error, touched, label, children, mandatory, style } = props;
    return (
        <div style={style}>
            {mandatory ? <span style={{ color: "red", fontWeight: "bold" }}>* </span> : ""}
            {label}
            {React.Children.map(children, child => {
                return React.cloneElement(child, {
                    field: {
                        value: value.includes(child.props.id),
                        onChange: handleChange,
                        onBlur: handleBlur
                    }
                });
            })}
            {touched && <InputFeedback error={error} />}
        </div>
    );
};
