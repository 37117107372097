import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "react-apollo";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";

import { UPSERT_INVOICE_CUSTOMER } from "GraphQLMutations";
import { useLanguage } from "Providers";
import { InvoiceCustomer } from "Types";
import { Button, Box, Header, Flex, RHFormInput, Text, NewDivider, Input } from "Atoms";
import { ModalActions, ModalBody } from "Molecules";

type Props = {
    modalsData: InvoiceCustomer;
    shopId: string;
    closeModal: () => void;
    handleModalNotificationOut: (messageNotification: any) => void;
    resetMessages: () => void;
    updateQuery: (updatedCustomer: any) => void;
};

const InvoiceCustomerForm: React.FC<Props> = ({
    modalsData,
    shopId,
    closeModal,
    handleModalNotificationOut,
    resetMessages,
    updateQuery
}) => {
    const { translate } = useLanguage();
    const invoiceCustomer = modalsData;
    const [editIndexes, setEditIndexes] = useState<number[]>([]);
    const [search, setSearch] = useState("");
    const [disableSearch, setDisableSearch] = useState(false);
    const [upsertInvoiceCustomer] = useMutation(UPSERT_INVOICE_CUSTOMER);

    const validationSchema = Yup.object().shape({
        invoiceAddress: Yup.object().shape({
            name: Yup.string().required(translate("formErrorMissing")),
            addressLine: Yup.string().required(translate("formErrorMissing")),
            city: Yup.string().required(translate("formErrorMissing")),
            postCode: Yup.string()
                .matches(/^(?=.*[0-9])[ 0-9]+$/, translate("wrongFormat"))
                .min(5, translate("mustBeFiveDigits"))
                .max(5, translate("mustBeFiveDigits"))
                .required(translate("formErrorMissing"))
        }),
        organisationNumber: Yup.string()
            .matches(/^[0-9.-]*$/, translate("onlyNumbersAlt"))
            .required(translate("formErrorMissing")),
        contactPersons: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(translate("formErrorMissing")),
                personalNumber: Yup.string().required(translate("formErrorMissing")),
                phoneNumber: Yup.string()
                    .matches(/^(?=.*[0-9])[ 0-9]+$/, translate("wrongFormat"))
                    .required(translate("formErrorMissing"))
            })
        )
    });

    const initialValues = {
        id: invoiceCustomer.id || null,
        invoiceAddress: invoiceCustomer.invoiceAddress || {
            name: "",
            addressLine: "",
            city: "",
            postCode: ""
        },
        organisationNumber: invoiceCustomer.organisationNumber || "",
        invoiceReference: invoiceCustomer.invoiceReference || "",
        customerNumber: invoiceCustomer.customerNumber || "",
        contactPersons: invoiceCustomer.contactPersons || [{ name: "", personalNumber: "", phoneNumber: "" }]
    };

    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting, isValid, defaultValues },
        getValues
    } = useForm<InvoiceCustomer>({
        resolver: yupResolver(validationSchema),
        mode: "onBlur",
        defaultValues: initialValues
    });

    const values = getValues();

    const { fields, prepend, remove } = useFieldArray({ control, name: "contactPersons" });

    const contactPersonsValues = values.contactPersons.filter(person =>
        person?.name.toLowerCase().includes(search.toLowerCase())
    );
    const contactPersonsFields = fields.filter(person => person?.name.toLowerCase().includes(search.toLowerCase()));

    const handlePrepend = () => {
        setDisableSearch(true);
        setSearch("");
        prepend(
            {
                name: "",
                personalNumber: "",
                phoneNumber: ""
            },
            { shouldFocus: true }
        );
        setEditIndexes(prev => [0, ...prev.map(index => index + 1)]);
    };

    const toggleEditIndex = (index: number) => {
        setEditIndexes(prev => (prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]));
    };
    const Row = (contactPerson: any, index: number) => {
        const isEditing = editIndexes.includes(index);
        const bg = index % 2 === 0 ? "white" : "gray.200";
        const getIndex = fields.findIndex((person: any) => person.id === contactPerson.id);

        return (
            <Flex
                key={index}
                p={2}
                backgroundColor={bg}
                marginBottom={1}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
                height={"60px"}
            >
                <Text m={0} flexBasis={"30px"}>
                    {index + 1}
                </Text>
                {!isEditing ? (
                    <>
                        <Text flexBasis="200px" m={0}>
                            {contactPersonsValues[index]?.name}
                        </Text>
                        <Text flexBasis="170px" m={0}>
                            {contactPersonsValues[index]?.personalNumber}
                        </Text>
                        <Text flexBasis="150px" m={0}>
                            {contactPersonsValues[index]?.phoneNumber}
                        </Text>
                    </>
                ) : (
                    <>
                        <RHFormInput
                            height="30px"
                            mt={6}
                            name={`contactPersons[${getIndex}].name`}
                            control={control}
                            placeholder={translate("name")}
                        />
                        <RHFormInput
                            height="30px"
                            mt={6}
                            name={`contactPersons[${getIndex}].personalNumber`}
                            control={control}
                            placeholder={translate("personalNumberPlaceholder")}
                        />
                        <RHFormInput
                            height="30px"
                            mt={6}
                            name={`contactPersons[${getIndex}].phoneNumber`}
                            control={control}
                            placeholder={translate("phone")}
                        />
                    </>
                )}
                <Box flexBasis="102px">
                    <Box
                        as={FiEdit}
                        mr={4}
                        color="blue.500"
                        cursor={"pointer"}
                        onClick={() => toggleEditIndex(index)}
                    />
                    <Box
                        as={FiTrash}
                        color="red.500"
                        cursor={"pointer"}
                        onClick={() => {
                            remove(index);
                            setEditIndexes([]);
                        }}
                    />
                </Box>
            </Flex>
        );
    };

    const submitInvoiceCustomer = async (values: InvoiceCustomer) => {
        resetMessages();
        try {
            const invoiceCustomer = { ...values, shopId };
            await upsertInvoiceCustomer({
                variables: {
                    invoiceCustomer
                },
                update: (_, { data: { upsertInvoiceCustomer } }) => {
                    updateQuery((prev: any) => {
                        let updatedData;
                        if (invoiceCustomer.id) {
                            updatedData = prev.getShopInvoiceCustomers.map((cachedCustomer: InvoiceCustomer) =>
                                upsertInvoiceCustomer.id === cachedCustomer.id ? upsertInvoiceCustomer : cachedCustomer
                            );
                        } else {
                            updatedData = prev.getShopInvoiceCustomers.concat([upsertInvoiceCustomer]);
                        }
                        return {
                            getShopInvoiceCustomers: updatedData
                        };
                    });

                    handleModalNotificationOut(`Fakturakund är nu ${invoiceCustomer.id ? "uppdaterad" : "skapad"}`);
                }
            });
            setDisableSearch(false);
        } catch (error) {
            console.log("error", error);
        }
    };
    return (
        <form onSubmit={handleSubmit(submitInvoiceCustomer)}>
            <ModalBody>
                <Box>
                    <Header as="h3" fontSize="xl">
                        {translate("companyInformation")}
                    </Header>
                    <RHFormInput
                        formLabel={translate("companyName")}
                        control={control}
                        name="invoiceAddress.name"
                        isMandatory
                        placeholder={translate("companyName")}
                    />
                    <RHFormInput
                        formLabel={translate("address")}
                        name="invoiceAddress.addressLine"
                        control={control}
                        isMandatory
                        placeholder={translate("address")}
                    />
                    <RHFormInput
                        formLabel={translate("postCode")}
                        name="invoiceAddress.postCode"
                        type="number"
                        isMandatory
                        control={control}
                        placeholder={translate("postCode")}
                    />
                    <RHFormInput
                        formLabel={translate("city")}
                        name="invoiceAddress.city"
                        control={control}
                        isMandatory
                        placeholder={translate("city")}
                    />
                    <RHFormInput
                        formLabel={translate("organizationNumber")}
                        name="organisationNumber"
                        control={control}
                        isMandatory
                        placeholder={translate("organizationNumber")}
                    />
                    <RHFormInput
                        formLabel={translate("reference")}
                        name="invoiceReference"
                        control={control}
                        placeholder={translate("referenceDetails")}
                    />
                    <RHFormInput
                        formLabel={translate("customerNumber")}
                        name="customerNumber"
                        control={control}
                        placeholder={translate("customerNumber")}
                    />
                    <Header as="h3" fontSize="xl">
                        {translate("contactPersons")}
                    </Header>
                    <Flex p={2} justifyContent="space-between" alignItems="center" textAlign="center">
                        <Box>
                            <Input
                                mb={2}
                                placeholder={`${translate("searchByName")}...`}
                                value={search}
                                isDisabled={disableSearch}
                                onChange={(e: any) => {
                                    setSearch(e.target.value);
                                    setEditIndexes([]);
                                }}
                            />
                            {disableSearch && <Text fontSize="sm" color="red.500">{translate("saveToSearch")}</Text>}
                        </Box>
                        <Button mb={2} onClick={handlePrepend}>
                            {translate("addMorePersons")}
                        </Button>
                    </Flex>
                    <Flex p={2} justifyContent="space-between" alignItems="center" textAlign="center">
                        <Text fontWeight="bold" flexBasis="30px" m={0}>
                            #
                        </Text>
                        <Text fontWeight="bold" flexBasis="200px" m={0}>
                            {translate("name")}
                        </Text>
                        <Text fontWeight="bold" flexBasis="170px" m={0}>
                            {translate("personalNumber")}
                        </Text>
                        <Text fontWeight="bold" flexBasis="150px" m={0}>
                            {translate("phone")}
                        </Text>
                        <Text fontWeight="bold" m={0}>
                            {translate("edit")}/{translate("delete")}
                        </Text>
                    </Flex>
                    <NewDivider color="gray.400" my={4} />
                    {contactPersonsFields.map((contactPerson, index) => Row(contactPerson, index))}
                </Box>
            </ModalBody>
            <ModalActions display="flex" justifyContent="flex-end">
                <Button type="submit" disabled={!isValid} themeColor="green">
                    {translate("save")}
                </Button>
            </ModalActions>
        </form>
    );
};

export default InvoiceCustomerForm;
