import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { groupBy } from "lodash";
import { IoIosWarning } from "@react-icons/all-files/io/IoIosWarning";

import {
    Header,
    Button,
    Box,
    Text,
    Checkbox,
    Flex,
    RHCheckBoxInput,
    ToolTip,
    NewList as List,
    NewListItem
} from "Atoms";
import { IModalContext, Languagekey, useLanguage } from "Providers";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modalNames } from "Constants";
import { InvoiceOrder } from "Types";
import { MergeInvoicesSettings } from "./Invoices";

const groupByOptions = {
    ORG_NUMBER: "organisationNumber",
    CUSTOMER_NUMBER: "customerNumber"
};

type Props = {
    invoicesList: InvoiceOrder[];
    invoicesToMerge: string[];
    buildAndDowloadPDF: (
        invoiceList: InvoiceOrder[],
        groupedInvoices: { [key: string]: InvoiceOrder[] },
        markAllAsHandled: boolean
    ) => void;
};

export const MergeInvoicesModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate, translateWithMultipleArguments } = useLanguage();

    const { invoicesList, invoicesToMerge, buildAndDowloadPDF } = modalContent;

    let invoiceOrdersToMerge = [...invoicesList].filter(invoiceOrder => invoicesToMerge.includes(invoiceOrder.id));

    const hasHandledInList = invoiceOrdersToMerge.some(invoiceOrder => invoiceOrder.invoiceData.isHandled);
    const allHandled = invoiceOrdersToMerge.every(invoiceOrder => invoiceOrder.invoiceData.isHandled);
    const allHasCustomerNumber = invoiceOrdersToMerge.every(invoiceOrder => !!invoiceOrder.invoiceData.customerNumber);

    const { control, setValue } = useForm<MergeInvoicesSettings>({
        defaultValues: {
            includeHandled: hasHandledInList,
            markAllAsHandled: !allHandled,
            groupByField: [groupByOptions.ORG_NUMBER]
        },
        mode: "onBlur"
    });

    const groupByField = useWatch({ control, name: "groupByField" });
    const includeHandled = useWatch({ control, name: "includeHandled" });
    const markAllAsHandled = useWatch({ control, name: "markAllAsHandled" });

    if (!includeHandled) {
        invoiceOrdersToMerge = invoiceOrdersToMerge.filter(invoiceOrder => !invoiceOrder.invoiceData.isHandled);
    }

    const groupedInvoices = groupBy(invoiceOrdersToMerge, (item: any) =>
        groupByField.map(field => item.invoiceData[field]).join("_")
    );

    const invoiceText = `${invoiceOrdersToMerge.length} ${
        invoiceOrdersToMerge.length > 1 ? translate("invoicesAlt").toLowerCase() : translate("invoices")
    }`;
    const fileText = `${Object.keys(groupedInvoices).length} ${
        Object.keys(groupedInvoices).length > 1 ? translate("files").toLowerCase() : translate("file")
    }`;

    const groupedInvoicesText = translateWithMultipleArguments("invoicesWillBeMergedToFiles", [invoiceText, fileText]);

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.MERGE_INVOICES_MODAL)} size="xs" isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.MERGE_INVOICES_MODAL)} />
            <ModalHeader>{translate("handleInvoices")}</ModalHeader>
            <ModalBody overflow="auto">
                {hasHandledInList && (
                    <RHCheckBoxInput control={control} name="includeHandled">
                        {translate("includeHandledInvoices")}
                    </RHCheckBoxInput>
                )}
                {!allHandled && (
                    <RHCheckBoxInput control={control} name="markAllAsHandled">
                        {translate("markAllAsHandled")}
                    </RHCheckBoxInput>
                )}
                <Box as="h3">{translate("howToGroupInvoices")}</Box>
                <Flex>
                    {Object.values(groupByOptions).map((value, idx) => {
                        const isChecked = groupByField.includes(value);
                        const disableOption = value === groupByOptions.CUSTOMER_NUMBER && !allHasCustomerNumber;
                        return (
                            <Flex alignItems={"center"} justifyContent={"flex-start"} key={idx}>
                                {disableOption && (
                                    <ToolTip
                                        placement={"right"}
                                        text={translate("allInvoicesMustHaveCustomerNumber")}
                                        w="auto"
                                    >
                                        <Box as={IoIosWarning} color="orange.400" ml={4} />
                                    </ToolTip>
                                )}
                                <Checkbox
                                    key={value}
                                    checked={groupByField.includes(value)}
                                    onChange={() => {
                                        const updatedGroupBy = isChecked
                                            ? groupByField.filter(item => item !== value)
                                            : [...groupByField, value];
                                        setValue("groupByField", updatedGroupBy);
                                    }}
                                    ml={2}
                                    disabled={disableOption}
                                >
                                    {translate(value as Languagekey)}
                                </Checkbox>
                            </Flex>
                        );
                    })}
                </Flex>
                {groupByField.length === 0 && (
                    <Text color="red.700" mt={4}>
                        {translate("selectAtLeastOneGrouping")}
                    </Text>
                )}
                {groupByField.length != 0 && (
                    <Flex direction={"column"} color="blue.500" mt={4}>
                        <Text mb={2}>{groupedInvoicesText}</Text>
                        <List pl={8}>
                            {Object.keys(groupedInvoices).map((key, idx) => {
                                const fileName = `${translate("invoiceForCompany")}_${key}`;
                                return <NewListItem key={idx}>{fileName}</NewListItem>;
                            })}
                        </List>
                    </Flex>
                )}
            </ModalBody>
            <ModalActions>
                <Flex justifyContent={"flex-end"}>
                    <Button
                        themeColor="red"
                        mr={4}
                        onClick={() => {
                            closeModal(modalNames.MERGE_INVOICES_MODAL);
                        }}
                    >
                        {translate("cancel")}
                    </Button>
                    <Button
                        themeColor="green"
                        disabled={groupByField.length === 0 || invoiceOrdersToMerge.length === 0}
                        onClick={() => {
                            closeModal(modalNames.MERGE_INVOICES_MODAL);
                            buildAndDowloadPDF(invoiceOrdersToMerge, groupedInvoices, markAllAsHandled);
                        }}
                    >
                        {translate("mergeAndDownload")}
                    </Button>
                </Flex>
            </ModalActions>
        </Modal>
    );
};
