import React from "react";
import { Form, Formik } from "formik-next";
import * as Yup from "yup";

import { Modal, ModalHeader, ModalActions, ModalBody, ModalCloseBtn, FormInput } from "Molecules";
import { Button, Text, Box } from "Atoms";
import { modalNames } from "Constants";
import { IModalContext } from "Providers";
import { GroupSubscription } from "Types";

type Props = {
    groupSubscription: GroupSubscription;
    onSubmitChangeAmount: (values: GroupSubscription) => void;
};

export const ChangeSeatsAmountModal: React.FC<IModalContext<Props>> = ({ closeModal, modalContent }) => {
    const { userSubscriptions, totalSeats } = modalContent.groupSubscription;
    const usedSeats = userSubscriptions?.length ?? 0;

    const validationSchema = Yup.object().shape({
        totalSeats: Yup.number().min(usedSeats, `Kan inte vara mindre än ${usedSeats}, som redan används`)
    });

    return (
        <Modal
            size="xs"
            open={true}
            closeOnDimmerClick
            onClose={() => closeModal(modalNames.CHANGE_SEATS_AMOUNT_MODAL)}
        >
            <ModalCloseBtn onClick={() => closeModal(modalNames.CHANGE_SEATS_AMOUNT_MODAL)} />
            <ModalHeader className="space-between">Ändra antal abonnemang</ModalHeader>
            <Formik
                initialValues={modalContent.groupSubscription}
                validationSchema={validationSchema}
                onSubmit={values => {
                    closeModal(modalNames.CHANGE_SEATS_AMOUNT_MODAL);
                    modalContent.onSubmitChangeAmount(values);
                }}
            >
                {({ isValid }) => {
                    return (
                        <Form>
                            <ModalBody>
                                <Text fontSize="lg">
                                    Använda abonnemang:
                                    <Box as="span" fontWeight={600} ml={2}>
                                        {usedSeats} av {totalSeats}
                                    </Box>
                                </Text>
                                <FormInput
                                    name="totalSeats"
                                    placeholder="Antal abonnemang"
                                    formLabel="Nytt antal abonnemang"
                                    size="lg"
                                    isMandatory
                                    fullWidth
                                    bg="white"
                                />
                            </ModalBody>
                            <ModalActions>
                                <Button fullWidth themeColor="green" disabled={!isValid} type="submit">
                                    Spara och skicka mail
                                </Button>
                            </ModalActions>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};
