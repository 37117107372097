import React from "react";

import InvoiceCustomerForm from "./InvoiceCustomerForm";
import { Modal, ModalHeader, ModalCloseBtn } from "Molecules";
import { IModalContext, modals, useLanguage } from "Providers";
import { InvoiceCustomer } from "Types";

type Props = {
    updateQuery: <T>(mapFn: (previousQueryResult: T) => T) => void;
    handleModalNotificationOut: (messageNotification: any) => void;
    resetMessages: () => void;
    selectedShopId?: string;
    data: InvoiceCustomer;
};
export const EditInvoiceCustomerModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();
    const { selectedShopId, handleModalNotificationOut, resetMessages, updateQuery, data } = modalContent;
    if (!data) {
        return null;
    }

    const modalHeader = data.id ? translate("editInvoiceCustomer") : translate("createNewInvoiceCustomer");
    return (
        <Modal
            isScrolling
            open={true}
            onClose={() => closeModal(modals.editInvoiceCustomerModal)}
            size="md"
            placement="center"
            maxHeight="95vh"
        >
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalCloseBtn onClick={() => closeModal(modals.editInvoiceCustomerModal)} />
            <InvoiceCustomerForm
                updateQuery={updateQuery}
                modalsData={data}
                shopId={selectedShopId ?? ""}
                closeModal={() => closeModal(modals.editInvoiceCustomerModal)}
                handleModalNotificationOut={handleModalNotificationOut}
                resetMessages={resetMessages}
            />
        </Modal>
    );
};
