import React from "react";
import { FieldArray, Field } from "formik-next";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";

import { convertDayOfWeekToSwe, getNotUsedDayOfWeek } from "TempUtils";
import {
    SelectInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
    TableFooter,
    FormInput,
    IconButton
} from "Molecules";
import { Button, Box } from "Atoms";
import { ActiveHour } from "Types";
import { useLanguage } from "Providers";
import { convertDayOfWeekToLang } from "Utils";

type TimePickerFormikNextProps = {
    values: any;
    name: string;
    hideDate: boolean;
    deleteAllDay: boolean;
    rename: string;
};

type ActiveHourRowProps = {
    activeHours: ActiveHour[];
    name: string;
    mappedNotUsedDayList: { value: string; label: string }[];
    remove: any;
    hideDate: boolean;
    deleteAllDay: boolean;
};

const dayOfWeekObjectName = "dayOfWeek";

const ActiveHoursRow: React.FC<ActiveHourRowProps> = ({
    activeHours,
    name,
    mappedNotUsedDayList,
    remove,
    hideDate,
    deleteAllDay
}) => {
    const { userLanguage } = useLanguage();
    return (
        <>
            {activeHours.map(({ dayOfWeek }, i) => {
                const formikNameProperty = `${name}.${i}.${dayOfWeekObjectName}`;

                const options = [{ label: convertDayOfWeekToLang(userLanguage)(dayOfWeek), value: dayOfWeek }].concat(
                    mappedNotUsedDayList
                );

                return (
                    <TableRow key={i}>
                        <TableCell verticalAlign="top">
                            <Field name={formikNameProperty} as={SelectInput} options={options} />
                        </TableCell>
                        {!hideDate && (
                            <TableCell verticalAlign="top" width={2}>
                                <Field name={`${name}.${i}.startingHour`} placeholder={"hh:mm"} as={FormInput} />
                            </TableCell>
                        )}
                        {!hideDate && (
                            <TableCell verticalAlign="top" width={2}>
                                <Field name={`${name}.${i}.stoppingHour`} placeholder={"hh:mm"} as={FormInput} />
                            </TableCell>
                        )}
                        <TableCell verticalAlign="top">
                            <IconButton
                                themeColor="red"
                                icon={FiTrash}
                                onClick={() => remove(i)}
                                disabled={!deleteAllDay && activeHours.length === 1}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export const TimePickerFormikNext: React.FC<TimePickerFormikNextProps> = ({
    values,
    name,
    hideDate,
    deleteAllDay,
    rename
}) => {
    const { translate, userLanguage } = useLanguage();
    const activeHours = values || [];
    const notUsedDayList = getNotUsedDayOfWeek(values);
    const mappedNotUsedDayList = notUsedDayList.map(day => ({
        label: convertDayOfWeekToLang(userLanguage)(day.dayOfWeek) ?? day.swe,
        value: day.dayOfWeek
    }));

    const getNextActiveHour = () => {
        const lastActiveHour = activeHours[activeHours.length - 1];
        const [nextActiveHour] = notUsedDayList;

        return {
            dayOfWeek: nextActiveHour.dayOfWeek,
            startingHour: lastActiveHour?.startingHour ?? "",
            stoppingHour: lastActiveHour?.stoppingHour ?? ""
        };
    };

    return (
        <FieldArray
            name={name}
            render={arrayHelpers => {
                if (activeHours.length) {
                    return (
                        <Table wrapperProps={{ overflow: "inherit" }}>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>{translate("day")}</TableHeader>
                                    {!hideDate && <TableHeader>{translate("start")}</TableHeader>}
                                    {!hideDate && <TableHeader>{translate("end")}</TableHeader>}
                                    <TableHeader />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <ActiveHoursRow
                                    activeHours={activeHours}
                                    name={name}
                                    mappedNotUsedDayList={mappedNotUsedDayList}
                                    remove={arrayHelpers.remove}
                                    hideDate={hideDate}
                                    deleteAllDay={deleteAllDay}
                                />
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell>
                                        <Button
                                            disabled={activeHours.length === 7}
                                            onClick={() => {
                                                const nextActiveHour = getNextActiveHour();
                                                arrayHelpers.push(nextActiveHour);
                                            }}
                                        >
                                            {rename ? rename : "Lägg till öppettider"}{" "}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    );
                } else {
                    return (
                        <Button
                            disabled={activeHours.length === 7}
                            type="button"
                            onClick={() => {
                                const nextActiveHour = getNextActiveHour();
                                arrayHelpers.push(nextActiveHour);
                            }}
                        >
                            {rename ? rename : "Lägg till öppettider"}
                        </Button>
                    );
                }
            }}
        />
    );
};
